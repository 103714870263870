import React from 'react'
import { graphql } from 'gatsby'
import { MarkdownContent } from '../components/MarkdownRenderer'
import { Helmet } from 'react-helmet'

const BasePage = ({ frontmatter, html }) => (
  <>
    <Helmet title={frontmatter.title} />
    <div className={'container has-navbar-fixed-top m-b-xl'}>
      <article className='message'>
        <div className='message-header'>
          <p>{frontmatter.title}</p>
        </div>
        <div className='message-body'>
          <MarkdownContent html={html} />
        </div>
      </article>
    </div>
  </>
)

export default ({ data: { markdownRemark } }) => (
  <BasePage {...markdownRemark} />
)

export const pageQuery = graphql`
  query Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid (maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
